import Vue from 'vue'
import VueRouter from 'vue-router'
import Management from '@/router/Account'
import Card from '@/router/Card'
import { i18n, selectedLanguage, localizeCurrentRoute, getAllTranslationsOfRoute, getEngPath } from '@/locales'
import SettingsStorageHelper from '@/utils/helpers/core/settings/settings'

Vue.use(VueRouter)

const allowedPathsRegex = [
  /^\/login$/,
  /^\/change-password\/[\w.]+$/,
  /^\/reset-password$/,
  /^\/error$/,
  /^(?:\/receipt\b)(?:\/[\w]+){1}$/,
  /^\/public\/api\/v1\/auth\/sso\/callback/,
  /^\/receipt-page$/,
  /^\/public\/api\/v1\/portal\/receipts/,
  process.env.VUE_APP_SSO_ADDITIONAL_RETURN_URL_REGEX,
  /^\/static\//
]

export function isAuthRequired(path, to = null) {
  const engPath = getEngPath(to)
  for (let index = 0; index < allowedPathsRegex.length; index++) {
    if (engPath && engPath.match(allowedPathsRegex[index])) return false
    if (path.match(allowedPathsRegex[index])) return false
  }
  return true
}

const routes = [
  {
    path: process.env.VUE_APP_SSO_ADDITIONAL_RETURN_URL_PATH,
    name: 'sso-callback',
    component: {},
    redirect: () => {
      window.location.href = '/public/api/v1/auth/sso/callback' + window.location.search
      return '/public/api/v1/auth/sso/callback' // not important since redirecting
    }
  },
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "login" */ '@/views/Login'),
    alias: getAllTranslationsOfRoute('login')
  },
  {
    path: '/receipt-page',
    name: 'receipt-page',
    component: () => import(/* webpackChunkName: "receipt-page" */ '@/views/ReceiptPage'),
    alias: getAllTranslationsOfRoute('receipt-page')
  },
  {
    path: '/receipt/:srfid',
    name: 'receipt',
    component: () => import(/* webpackChunkName: "receipt" */ '@/views/Receipt'),
    alias: getAllTranslationsOfRoute('receipt')
  },
  {
    path: '/reset-password',
    name: 'reset-password',
    component: () => import(/* webpackChunkName: "reset-password" */ '@/views/PasswordReset.vue'),
    alias: getAllTranslationsOfRoute('reset-password')
  },
  {
    path: '/change-password/:token',
    name: 'change-password',
    component: () => import(/* webpackChunkName: "change-password" */ '@/views/PasswordChange.vue'),
    alias: getAllTranslationsOfRoute('change-password')
  },
  {
    path: '/error',
    name: 'error',
    component: () => import(/* webpackChunkName: "error" */ '@/views/Error'),
    alias: getAllTranslationsOfRoute('error')
  },
  {
    path: '/',
    component: () => import(/* webpackChunkName: "main" */ '@/views/Main'),
    children: [
      {
        path: '/',
        name: 'passenger-area',
        component: () => import(/* webpackChunkName: "passenger-area" */ '@/views/PassengerArea')
      },
      Card,
      Management
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  linkActiveClass: 'active',
  linkExactActiveClass: 'active',
  routes
})

router.beforeEach((to, from, next) => {
  const decodedURI = decodeURI(to.path)
  if (decodedURI !== to.path) {
    return next(decodedURI)
  }

  const isLoggedIn = SettingsStorageHelper.isConfigurationExist()

  if (isLoggedIn || !isAuthRequired(to.path, to)) {
    return next()
  }
  // if the user is not authenticated, `next` is called twice
  const localizedLoginPath = `${i18n.t('route.path.login')}`
  next(localizedLoginPath)
})

router.beforeEach((to, from, next) => {
  const lang = selectedLanguage['isoUnderscored']

  i18n.locale = lang in i18n.messages ? lang : i18n.fallbackLocale

  next()
})

router.beforeEach((to, from, next) => {
  const localizedCurrentRoute = localizeCurrentRoute(to)
  return to.fullPath !== localizedCurrentRoute ? next({ path: localizedCurrentRoute }) : next()
})

export default router
