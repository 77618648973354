import dropNullsFromObject from '@/utils/helpers/convert/dropNullsFromObject'

/**
 * Changes user object with data to those used in application.
 *
 * @param {Object} data - Input data received from API
 * @param {?string} data.email - User email address
 * @param {?string} data.first_name - User first name
 * @param {?string} data.last_name - User last name
 * @param {?string} data.username - User username
 * @param {?Object} data.permissions - User permissions for GUI
 * @param {?number} data.max_user_idle_time - User max idle time
 * @param {boolean} [omitNulls=false] - Return user object without null properties
 * @param {boolean} data.is_sso_shadow_user - Is user a sso shadow user
 * @param {?string} data.last_correct_login_date - Last correct login date
 * @param {?string} data.last_incorrect_login_date - Last incorrect login date
 * @param {number} data.incorrect_login_count - Incorrect login counter
 * @param {number} data.correct_login_count - Correct login counter
 * @param {?string} data.last_logout_date - Last logout date
 * @param {number} data.passwd_rule_digits_count - Password rule - minimal digit required
 * @param {number} data.passwd_rule_length - Password rule - minimal password length required
 * @param {number} data.passwd_rule_big_letters_count - Password rule - minimal big letters required
 * @param {number} data.passwd_rule_small_letters_count - Password rule - minimal small letters required
 * @param {number} data.passwd_rule_special_chars_count - Password rule - minimal special chars required
 * @param {number} data.passwd_rule_max_repeat - Password rule - maximum repeating in last passwords
 * @param {boolean} data.passwd_rule_must_change_on_login - Password must change on next login
 * @param {number} data.passwd_rule_must_change_after_days_count - Password must change after x-days
 * @param {number} data.passwd_rule_must_change_after_login_count - Password must change after x-logins
 *
 * @returns {
    {
      first_name: (string|null),
      last_name: (string|null),
      email: (string|null),
      username: (string|null),
      permissions: array,
      maxUserIdleTime: (number|null),
      is_sso_shadow_user: boolean,
      last_correct_login_date: (string|null),
      last_incorrect_login_date: (string|null),
      incorrect_login_count: (number),
      correct_login_count: (number),
      last_logout_date: (string|null),
      passwd_rule_digits_count: (number),
      passwd_rule_length: (number),
      passwd_rule_big_letters_count: (number),
      passwd_rule_small_letters_count: (number),
      passwd_rule_special_chars_count: (number),
      passwd_rule_max_repeat: (number),
      passwd_rule_must_change_on_login: (boolean),
      passwd_rule_must_change_after_days_count: (number),
      passwd_rule_must_change_after_login_count: (number)
    }
  }
 */
export default (data, omitNulls) => {
  if (omitNulls !== true && omitNulls !== false) {
    omitNulls = false
  }

  let user = {
    email: data.email || null,
    first_name: data.first_name || null,
    last_name: data.last_name || null,
    username: data.username || null,
    permissions: data.permissions || null,
    is_sso_shadow_user: data.is_sso_shadow_user || false,
    maxUserIdleTime: data.max_user_idle_time || parseInt(process.env.VUE_APP_MAX_USER_IDLE_TIME) || 0,
    last_correct_login_date: data.last_correct_login_date || null,
    last_incorrect_login_date: data.last_incorrect_login_date|| null,
    incorrect_login_count: parseInt(data.incorrect_login_count || 0),
    correct_login_count: parseInt(data.correct_login_count|| 0),
    last_logout_date: data.last_logout_date || null,
    passwd_rule_digits_count: parseInt(data.passwd_rule_digits_count || 0),
    passwd_rule_length: parseInt(data.passwd_rule_length || 0),
    passwd_rule_big_letters_count: parseInt(data.passwd_rule_big_letters_count || 0),
    passwd_rule_small_letters_count: parseInt(data.passwd_rule_small_letters_count || 0),
    passwd_rule_special_chars_count: parseInt(data.passwd_rule_special_chars_count || 0),
    passwd_rule_max_repeat: parseInt(data.passwd_rule_max_repeat || 0),
    passwd_rule_must_change_on_login: data.passwd_rule_must_change_on_login || false,
    passwd_rule_must_change_after_days_count: parseInt(data.passwd_rule_must_change_after_days_count || 0),
    passwd_rule_must_change_after_login_count: parseInt(data.passwd_rule_must_change_after_login_count || 0)
  }

  if (omitNulls) {
    dropNullsFromObject(user)
  }

  return user
}
