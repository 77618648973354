import { getAllTranslationsOfRoute } from '@/locales'

export default {
  path: 'card',
  component: () => import(/* webpackChunkName: "card" */ '@/views/Card/Card'),
  alias: getAllTranslationsOfRoute('card'),
  children: [
    {
      path: '/',
      name: 'card',
      component: () => import(/* webpackChunkName: "card" */ '@/views/Card/View/CardView')
    },
    {
      path: 'add',
      name: 'card-add',
      component: () => import(/* webpackChunkName: "card-add" */ '@/views/Card/Add/CardAdd'),
      alias: getAllTranslationsOfRoute('card-add'),
    },
    {
      path: 'trips-history',
      name: 'card-trips-history',
      component: () => import(/* webpackChunkName: "card-trips-history" */ '@/views/Card/TripsHistory/CardTripsHistory'),
      alias: getAllTranslationsOfRoute('card-trips-history'),
    },
    {
      path: 'trips-history/:id',
      name: 'card-trips-history-table',
      component: () => import(/* webpackChunkName: "card-trips-history-table" */ '@/views/Card/TripsHistory/CardTripsHistoryTable'),
      alias: getAllTranslationsOfRoute('card-trips-history-table'),
    },
    {
      path: 'payments-history',
      name: 'card-payments-history',
      component: () => import(/* webpackChunkName: "card-payments-history" */ '@/views/Card/PaymentsHistory/CardPaymentsHistory'),
      alias: getAllTranslationsOfRoute('card-payments-history'),
    },
    {
      path: 'payments-history/:id',
      name: 'card-payments-history-table',
      component: () => import(/* webpackChunkName: "card-payments-history-table" */ '@/views/Card/PaymentsHistory/CardPaymentsHistoryTable'),
      alias: getAllTranslationsOfRoute('card-payments-history-table'),
    },
    {
      path: 'products-history',
      name: 'card-products-history',
      component: () => import(/* webpackChunkName: "card-products-history" */ '@/views/Card/ProductsHistory/CardProductsHistory'),
      alias: getAllTranslationsOfRoute('card-products-history'),
    },
    {
      path: 'products-history/:id',
      name: 'card-products-history-table',
      component: () => import(/* webpackChunkName: "card-products-history-table" */ '@/views/Card/ProductsHistory/CardProductsHistoryTable'),
      alias: getAllTranslationsOfRoute('card-products-history-table'),
    },
    {
      path: 'favourites/:id',
      name: 'card-favourites-table',
      component: () => import(/* webpackChunkName: "card-favourites-table" */ '@/views/Card/Favourites/CardFavouritesTable'),
      alias: getAllTranslationsOfRoute('card-favourites-table'),
    }
  ]
}
