import axios from 'axios'
import {requestInterceptors, responseInterceptors} from '@/mixins/axios/_interceptors'

const _createAxios = axios.create

axios.defaults.withCredentials = true
axios.defaults.headers['Accept'] = 'application/json'
axios.defaults.headers['Content-Type'] = 'application/json'

axios.defaults.interceptors = {
  request: requestInterceptors,
  response: responseInterceptors
}

axios.create = function createPatchedAxios(conf) {
  const instance = _createAxios(conf)
  const defaultIcs = axios.defaults.interceptors

  const reqInterceptors = defaultIcs && defaultIcs.request ? defaultIcs.request : false
  const resInterceptors = defaultIcs && defaultIcs.response ? defaultIcs.response : false

  if (reqInterceptors) {
    reqInterceptors.forEach(i => instance.interceptors.request.use(...i))
  }
  if (resInterceptors) {
    resInterceptors.forEach(i => instance.interceptors.response.use(...i))
  }

  return instance
}

responseInterceptors.forEach(i => axios.interceptors.response.use(...i))

export default axios
