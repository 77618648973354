//import createAxiosErrorHandlingInstance from './_errorHandlingServiceClient'
//import { responseErrorUnwrap } from '@/utils/helpers/api'

/**
 * Post error message to backend
 *
 * @param {string} content
 * @param {Object} [context]
 * @returns {Promise<null,ErrorResponse>}
 */
// eslint-disable-next-line no-unused-vars
function postError(content, context) {
  //return new Promise((resolve, reject) => {
  //const instance = createAxiosErrorHandlingInstance()

  /* instance.post('/error', { content, context })
      .then(() => resolve())
      .catch(error => reject(responseErrorUnwrap(error)))*/
  //})
}

export default {
  postError
}
