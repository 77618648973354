import errorService from '@/api/ErrorHandlingService'

export default class ErrorService {
  static onError(err) {
    console.error('ErrorService (error):', err)
    // Send Error to Log Engine
    if (err instanceof Error) {
      ErrorService.sendErrorToLogEngine(err.message, err)
    } else {
      ErrorService.sendErrorToLogEngine(err)
    }
  }

  static onWarn(warn) {
    console.warn('ErrorService (warning):', warn)
  }

  static onInfo(info) {
    console.info('ErrorService (info):', info)
  }

  static onDebug(debug) {
    console.debug('ErrorService (debug):', debug)
  }

  static onGlobalErrorEvent(message, url, lineNo, columnNo, error) {
    if (!error) {
      error = `Error: ${message}\nURL: ${url}\nLine: ${lineNo}\nColumn: ${columnNo}`
    }

    ErrorService.onError(error)
  }

  /**
   * Send data upstream
   *
   * @param {string} errorMessage
   * @param {Object} [context]
   */
  static sendErrorToLogEngine(errorMessage, context) {
    if (process.env.NODE_ENV === 'development') {
      return
    }

    let convertedContext = {}

    Object.getOwnPropertyNames(context).forEach(function (key) {
      convertedContext[key] = this[key]
    }, context)

    errorService
      .postError(errorMessage, convertedContext)
      .catch(err => console.error(err))
  }
}
