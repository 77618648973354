import { getAllTranslationsOfRoute } from '@/locales'

export default {
  path: 'account',
  component: () => import(/* webpackChunkName: "account" */ '@/views/Account/Account'),
  alias: getAllTranslationsOfRoute('account'),
  children: [
    {
      path: 'edit',
      name: 'account-edit',
      component: () => import(/* webpackChunkName: "account-edit" */ '@/views/Account/AccountEdit'),
      alias: getAllTranslationsOfRoute('account-edit'),
    }
  ]
}
