import Users from '@/api/AuthService/users'
import SettingsStorageHelper from '@/utils/helpers/core/settings/settings'
import router from '@/router'
import {i18n} from '@/locales'
import {Notification} from 'uiv'
import json from '@/configuration.json'
import Vue from 'vue'
import SparkMD5 from 'spark-md5'

const ignoredPathsRegex = [
  /\/login$/,
  /\/user_is_logged[^/]/,
  /\/authenticate\/user$/,
  /\/public\/api\/v1\/portal\/receipts/
]

function isIgnoredPath(url) {
  for (let index = 0; index < ignoredPathsRegex.length; index++) {
    if (url.match(ignoredPathsRegex[index])) {
      return true
    }
  }
  return false
}

const pushToLoginOnUnauthorized = [
  response => response,
  error => {
    if (!isIgnoredPath(error.response.request.responseURL)
      && typeof error.response !== 'undefined'
      && 401 === error.response.status) {
      Users
        .isLogged(error.response.request.responseURL)
        .then(() => router.go(0))
        .catch(isLoggedError => {
          if (typeof isLoggedError.response !== 'undefined'
            && 401 === isLoggedError.response.status) {
            // shouldn'be store logout instead?
            SettingsStorageHelper.clear()
            Users.logout()
            router.push('/login')

            let notificationData = {
              type: 'warning',
              title: i18n.t('login.token_auth.notification_token_expired_title'),
              content: i18n.t('login.token_auth.notification_relogin_required_content'),
              duration: json.notification_duration.warning,
              uniqId: 'expiredTokenLogout'
            }

            if (isLoggedError.response.hasOwnProperty('data')
              && isLoggedError.response.data.hasOwnProperty('error')
              && 'TOKEN_EXPIRED' === isLoggedError.response.data.error
            ) {
              notificationData['content'] = i18n.t('login.token_auth.notification_token_expired_content')
            }

            Notification.notify(notificationData)
            return Promise.resolve()
          }
        })
    }

    return Promise.reject(error)
  }
]

const setPathHeaders = [
  request => {
    try {
      const tokenHash = Vue.$cookies.isKey('jwt_token_hash') ? Vue.$cookies.get('jwt_token_hash') : ''
      const requestRoute = {
        name: null,
        redirectedFrom: null,
        fullPath: null,
        query: null,
        hash: null,
        location: window.location.href
      }

      if (router && router.currentRoute) {
        requestRoute.name = router.currentRoute.name ?? null
        requestRoute.redirectedFrom = router.currentRoute.redirectedFrom ?? null
        requestRoute.fullPath = router.currentRoute.fullPath ?? null
        requestRoute.query = router.currentRoute.query ?? null
        requestRoute.hash = router.currentRoute.hash ?? null
      }

      let tokenMD5
      let requestRouteStr
      try {
        requestRouteStr = JSON.stringify(requestRoute)
        tokenMD5 = SparkMD5.hash(requestRouteStr + tokenHash)
      } catch (e) {
        tokenMD5 = ''
        console.log(
          {
            token_hash: tokenHash,
            request_route_str: requestRouteStr,
            msg: e.message
          }
        )
      }
      request.headers['X-S7-GUI-CALL-TRACE'] = btoa(JSON.stringify(
        {
          request_route: requestRoute,
          token: tokenMD5
        }
      ))
    } catch (e) {
      console.log(e)
    }
    return request
  }
]

export const requestInterceptors = [
  setPathHeaders
]
export const responseInterceptors = [
  pushToLoginOnUnauthorized
]
