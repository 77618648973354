import Vue from 'vue'
import Vuex from 'vuex'
import router from '@/router'
import {get} from 'lodash'
import {SettingsHelper} from '@/utils/helpers/core'
import Users from '@/api/AuthService/users'
import {notification} from './notification'
import { i18n } from '@/locales'

Vue.use(Vuex)

const path = {
    USERNAME: 'user.username',
    FIRST_NAME: 'user.first_name',
    LAST_NAME: 'user.last_name',
    PERMISSIONS: 'user.permissions',
    MAX_USER_IDLE_TIME: 'user.maxUserIdleTime',
    IS_SSO_SHADOW_USER: 'user.is_sso_shadow_user'
  },
  consoleCss = {
    green: 'color:green;font-weight:700',
    red: 'color:red;font-weight:700',
    orange: 'color:orange;font-weight:700',
    blue: 'color:blue;font-weight:700',
    black: 'color:black;font-weight:normal',
    chocolate: 'color:chocolate;font-weight:normal',
    darkSeaGreen: 'color:darkseagreen;font-weight:normal'
  }

const USER_IDLE_WATCH_EVENTS = ['mousedown', 'mousemove', 'keypress', 'scroll', 'touchstart'],
  THROTTLE_TIME = 0

const initialSetting = {
  user: {
    username: '',
    first_name: '',
    last_name: '',
    idleTime: 0,
    maxUserIdleTime: 0,
    isResettingIdleTime: false,
    last_correct_login_date: null,
    last_incorrect_login_date: null,
    incorrect_login_count: 0,
    correct_login_count: 0,
    last_logout_date: null,
    is_sso_shadow_user: false,
    passwd_rule_digits_count: 0,
    passwd_rule_length: 0,
    passwd_rule_big_letters_count: 0,
    passwd_rule_small_letters_count: 0,
    passwd_rule_special_chars_count: 0,
    passwd_rule_max_repeat: 0,
    passwd_rule_must_change_on_login: false,
    passwd_rule_must_change_after_days_count: 0,
    passwd_rule_must_change_after_login_count: 0
  },
  cards: []
}

export default new Vuex.Store({
  modules: {
    notification
  },
  state: () => {
    let initialState = Object.assign({}, initialSetting)

    if (SettingsHelper.isConfigurationExist()) initialState.user.username = SettingsHelper.get(path.USERNAME) || ''
    if (SettingsHelper.isConfigurationExist()) initialState.user.first_name = SettingsHelper.get(path.FIRST_NAME) || ''
    if (SettingsHelper.isConfigurationExist()) initialState.user.last_name = SettingsHelper.get(path.LAST_NAME) || ''
    if (SettingsHelper.isConfigurationExist()) initialState.user.permissions = SettingsHelper.get(path.PERMISSIONS) || ''
    if (SettingsHelper.isConfigurationExist()) initialState.user.maxUserIdleTime = SettingsHelper.get(path.MAX_USER_IDLE_TIME) || 0
    if (SettingsHelper.isConfigurationExist()) initialState.user.is_sso_shadow_user = SettingsHelper.get(path.IS_SSO_SHADOW_USER) || false

    return initialState
  },
  mutations: {
    replaceCards(state, cards) {
      state.cards = cards
    },
    pushCard(state, card) {
      state.cards.push(card)
    },
    removeCardById(state, id) {
      const cardIndex = state.cards.findIndex(o => o.id === parseInt(id))
      if (cardIndex !== -1) {
        state.cards.splice(cardIndex, 1)
      }
    },
    saveUserSettings(state, settings) {
      if (typeof settings !== 'object') {
        throw new Error('Property user should be an object!')
      }
      SettingsHelper.mergeConfiguration({user: settings})
      state.user = settings
    },
    clearSettings(state) {
      SettingsHelper.clear()
      state.user = Object.assign({}, initialSetting.user)
      state.cards = []
    },
    incrementIdleTime({user}) {
      ++user.idleTime
    },
    resetIdleTimer({user}) {
      if (!user.isResettingIdleTime) {
        user.isResettingIdleTime = true
        user.idleTime = 0

        setTimeout(() => user.isResettingIdleTime = false, THROTTLE_TIME)
      }
    },
    setMaxUserIdleTime({user}, time) {
      user.maxUserIdleTime = time
    }
  },
  actions: {
    updateUser({commit, state}, data) {
      commit('saveUserSettings', Object.assign({}, state.user, data))
      commit('setMaxUserIdleTime', get(data, 'maxUserIdleTime', undefined))
    },
    setCards({commit}, cards) {
      commit('replaceCards', cards)
    },
    addCard({commit}, card) {
      commit('pushCard', card)
    },
    removeCardById({commit}, id) {
      commit('removeCard', id)
    },
    resetConfiguration({commit}) {
      commit('clearSettings')
    },
    /**
     * Logout makes several jobs:
     *  - send user to login page
     *  - send logout request to the backend
     *  - clear local storage
     *  - reset vuex configuration store
     *  - clear incrementIdleTime interval
     * @param dispatch
     */
    async logout({dispatch}) {
      try {
        await router.push({ path: i18n.t('route.path.login') })
      } catch {
        // Avoid navigation duplicated
      }

      await Users.logout()
      dispatch('resetConfiguration')
      // clearInterval(this.incrementTime)
    },
    startWatchUserIdleTime({commit, getters}) {
      window.addEventListener('load', () => commit('resetIdleTimer'), true)
      USER_IDLE_WATCH_EVENTS.forEach((name) => {
        window.addEventListener(name, () => {
          if (getters['isUserIdleTimeSet']) {
            commit('resetIdleTimer')
          }
        }, true)
      })

      this.incrementTime = setInterval(() => {
        if (getters['isUserIdleTimeSet']) {
          commit('incrementIdleTime')
        }
      }, 1000)
    }
  },
  getters: {
    getUserPermissions(state) {
      return get(state, 'user.permissions', [])
    },
    hasRoutePermission: (_s, localGetters) => (level, routeName) => {
      if (typeof routeName === 'undefined' || routeName === null) {
        routeName = router.currentRoute.name
      }

      const permissions = localGetters.getUserPermissions,
        hasPermission = 'any' !== level
          ? (
            Object.prototype.hasOwnProperty.call(permissions, level)
            && permissions[level].includes(routeName)
          )
          : hasAnyRoutePermission(permissions, routeName)

      if ('true' === process.env.VUE_APP_PERMISSIONS_DEBUG) {
        // Using Emoji's
        console.debug(
          (hasPermission ? '🟢' : '🔴') + ' Checking %croute %cpermission\t(l: %c' + level + '%c)\t(r: %c' + routeName + '%c)\t%c' + hasPermission,
          consoleCss.darkSeaGreen,
          consoleCss.black,
          consoleCss.red,
          consoleCss.black,
          consoleCss.blue,
          consoleCss.black,
          consoleCss.orange
        )
      }

      return hasPermission
    },
    hasActionPermission: (_s, localGetters) => (level, action, routeName) => {
      if (typeof routeName === 'undefined' || routeName === null) {
        routeName = router.currentRoute.name
      }

      const permissions = localGetters.getUserPermissions,
        hasPermission = Object.prototype.hasOwnProperty.call(permissions, level)
          && permissions[level].includes(`${routeName}.${action}`)

      if ('true' === process.env.VUE_APP_PERMISSIONS_DEBUG) {
        // Using Emoji's
        console.debug(
          (hasPermission ? '🟢' : '🔴') + ' Checking %caction %cpermission\t(l: %c' + level + '%c)\t(r: %c' + routeName + '%c)\t(a: %c' + action + '%c)\t%c' + hasPermission,
          consoleCss.chocolate,
          consoleCss.black,
          consoleCss.red,
          consoleCss.black,
          consoleCss.blue,
          consoleCss.black,
          consoleCss.green,
          consoleCss.black,
          consoleCss.orange
        )
      }

      return hasPermission
    },
    getUserFullName: state => {
      return `${state.user.firstName} ${state.user.lastName}`.trim()
    },
    getCard: state => id => state.cards.find(o => o.id === parseInt(id)),
    isUserIdleTimeExceeded(state, getters) {
      return !getters.isUserIdleTimeSet ? false : state.user.idleTime >= state.user.maxUserIdleTime
    },
    isUserIdleTimeSet({user}) {
      return user.maxUserIdleTime !== 0
    },
    getIdleTimeLeft({user}) {
      return user.maxUserIdleTime - user.idleTime
    }
  }
})

function hasAnyRoutePermission(permissions, routeName) {
  let out = false
  Object.keys(permissions).forEach(permissionLevel => {
    out = out || permissions[permissionLevel].includes(routeName)
  })

  return out
}
