import {Notification} from 'uiv'
import {v4 as uuidv4} from 'uuid'
import ErrorService from '@/utils/helpers/ErrorService'
import {i18n} from '@/locales'

export const notification = {
  namespaced: true,
  state: {
    notificationsList: []
  },
  actions: {
    sendNotification({commit, state}, payload) {
      // Creating object with default values
      let newNotification = {
        uuid: uuidv4(),
        type: 'info',
        title: '',
        content: '',
        duration: 5000,
        icon: null,
        html: false,
        storeInList: true,
        uniqId: null,
        translatedTitle: null,
        translatedContent: null,
        traceId: null
      }

      //unpack
      let data = payload.data
      const dataDefault = payload.dataDefault

      // noinspection JSUnresolvedVariable
      if ((!data || !data.objectType || 'notification' !== data.objectType) && dataDefault) {
        data = dataDefault
      }

      Object
        .keys(data)
        .forEach(key => {
          // Clear unsupported fields
          if (!['type', 'title', 'content', 'duration', 'html', 'storeInList', 'translatedTitle', 'translatedContent', 'traceId'].includes(key)) {
            delete (data[key])
          }
        })

      // Casting data/validating
      if (true === data['html']) {
        newNotification.html = true
      }
      if (false === data['storeInList']) {
        newNotification.storeInList = false
      }
      if (typeof data['duration'] !== 'undefined' && null !== data['duration']) {
        newNotification.duration = parseInt(data['duration'])
      }
      ['type', 'title', 'content', 'icon', 'uniqId', 'translatedTitle', 'translatedContent', 'traceId'].forEach(v => {
        if (typeof data[v] !== 'undefined' && null !== data[v]) {
          newNotification[v] = data[v].toString()
        }
      })

      // NEW message format!
      if (newNotification.translatedTitle !== null) {
        newNotification.title = i18n.t(newNotification.translatedTitle)
      }
      if (newNotification.translatedContent !== null) {
        newNotification.content = i18n.t(newNotification.translatedContent)
      }
      if (newNotification.traceId !== null) {
        newNotification.content += '\n\nTRACE-ID: ' + newNotification.traceId
      }

      // Is unique ID is defined and there is object with this ID in list we drop this notification
      // THIS REQUIRES 'storeInList' PROPERTY TO BE SET TO TRUE
      // IF WE WANT "FIX" THIS WE NEED ANOTHER LIST WHERE ALL NOTIFICATIONS WILL BE STORED
      if (newNotification.uniqId !== null && typeof state.notificationsList.find(o => o.uniqId === newNotification.uniqId) !== 'undefined') {
        return
      }

      // Creating notification
      const start = +new Date()
      Notification.notify(newNotification).then(() => {
        const closeDelay = +new Date() - start - 300 // 300ms is a lag

        if ('true' === process.env.VUE_APP_NOTIFICATIONS_DEBUG) {
          ErrorService.onDebug({
            name: 'NOTIFICATION:DISMISS',
            dismissedAfter: closeDelay,
            dismissed: (closeDelay >= newNotification.duration) ? 'auto' : 'click'
          })
        }

        return closeDelay < newNotification.duration
      }).then(closedByHuman => {
        if (closedByHuman) {
          commit('removeNotification', newNotification)
        }
      })

      switch (newNotification.type) {
        case 'info':
        case 'warning':
          if (null === newNotification.icon) {
            newNotification.icon = 'glyphicon glyphicon-info-sign'
          }
          break
        case 'success':
          if (null === newNotification.icon) {
            newNotification.icon = 'glyphicon glyphicon-ok-sign'
          }
          break
        case 'danger':
          if (null === newNotification.icon) {
            newNotification.icon = 'glyphicon glyphicon-remove-sign'
          }
          break
      }

      // Adding notification to array
      if (true === newNotification.storeInList) {
        commit('addNotification', newNotification)
      }
    }
  },
  mutations: {
    addNotification(state, notification) {
      state.notificationsList.unshift(notification)
    },
    removeNotification(state, notification) {
      state.notificationsList = state.notificationsList.filter(o => o.uuid !== notification.uuid)
    },
    clearAllNotifications(state) {
      state.notificationsList = []
    }
  },
  getters: {}
}
