<template>
  <router-view />
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import Users from '@/api/AuthService/users'
import { isAuthRequired } from '@/router'
import moment from 'moment'
import { getEngPath } from '@/locales'

export default {
  name: 'App',
  computed: {
    ...mapGetters(['isUserIdleTimeExceeded', 'getIdleTimeLeft']),
    ...mapState({
      maxUserIdleTime: state => state.user.maxUserIdleTime
    }),
  },
  watch: {
    isUserIdleTimeExceeded(timeExceeded) {
      if (isAuthRequired(window.location.pathname) && timeExceeded) {
        this.logout()
        this.$notify({
          type: 'warning',
          title: this.$t('idle_time.idle_time_exceeded_notification_title'),
          content: this.$t('idle_time.idle_time_exceeded_notification_content'),
          duration: 10000
        })
      }
    },
    getIdleTimeLeft(idleSecondsLeft) {
      const timeLeft = this.formatDurationMinutes(idleSecondsLeft)
      const maxTime = parseInt(this.formatDurationMinutes(this.maxUserIdleTime))
      const time = maxTime - timeLeft

      if (isAuthRequired(window.location.pathname) && idleSecondsLeft == 60) {
        this.$notify({
          type: 'warning',
          title: this.$t('idle_time.idle_time_warning_notification_title', {maxTime}),
          content: this.$t('idle_time.idle_time_warning_notification_content', {timeLeft, time}),
          duration: 8000
        })
      }
    }
  },
  created() {
    this.startWatchUserIdleTime()
    const engPath = getEngPath(this.$router.history.pending)
    if (isAuthRequired(engPath)) {
      Users
        .isLogged(engPath)
        // Logouts user if error - means that user is not logged in
        .catch(async () => {
          await this.logout()
        })
    }
  },
  methods: {
    ...mapActions(['logout', 'startWatchUserIdleTime']),
    formatDurationMinutes(time) {
      return moment.utc(
        moment.duration(time, 'seconds').asMilliseconds()
      ).format('m')
    }
  }
}
</script>
